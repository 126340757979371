<template>
  <div class="waitlist">
    <button>
      <router-link to="/join-waitlist">Join Us in Our Journey</router-link>
    </button>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.waitlist {
  display: flex;
  justify-content: center; 
  cursor: pointer;

  button {
    background-color: #5e7d9c;
    border-radius: 30px;
    font-size: 15px;
    border: none;
    height: 35px;
    padding: 5px 30px;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s; 

    &:hover {
      background-color: #4a657d; 
    }

    a {
      text-decoration: none;
      color: #fff;
    }
  }

  // Responsive styles
  @media (max-width: 768px) {
    margin: 20px 0; 

    button {
      font-size: 14px; 
      padding: 5px 20px; 
    }
  }

  @media (max-width: 480px) {
    button {
      font-size: 12px;
      height: 30px;
      padding: 5px 15px; 
    }
  }
}
</style>
