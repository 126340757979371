import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/LandingPage/HomeView.vue";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",

    component: () => import("../views/LandingPage/AboutUs.vue"),
  },
  {
    path: "/join-waitlist",
    name: "join-waitlist",
    component: () => import("../views/LandingPage/JoinWaitlist.vue"),
  },
  {
    path: "/register",
    name: "signup",
    component: () => import("../Auth/SignUp.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../Auth/LogIn.vue"),
  },
  {
    path: "/tutor-dashboard",
    name: "tutor-dashboard",
    component: () => import("../views/Tutor/Dashboard.vue"),
    // meta: { requiresAuth: true },
  },
  {
    path: "/learner",
    name: "learner",
    component: () => import("../views/Learner/index.vue"),
    // meta: { requiresAuth: true },
  },
  {
    path: "/my-courses",
    name: "my-courses",
    component: () => import("../views/Tutor/MyCourses.vue"),
    // meta: { requiresAuth: true },
  },
  {
    path: "/upload-course",
    name: "upload-course",
    component: () => import("../views/Tutor/UploadCourses.vue"),
    // meta: { requiresAuth: true },
  },
  {
    path: "/course-details",
    name: "course-details",
    component: () => import("../views/Tutor/CourseDetails.vue"),
    // meta: { requiresAuth: true },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("../views/Tutor/Notifications.vue"),
    // meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/Tutor/Profile.vue"),
    // meta: { requiresAuth: true },
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () => import("../views/LandingPage/ContactUs.vue"),
    // meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the user's token exists in the Vuex store
    const token = store.state.user.token;
    if (!token) {
      // Redirect to login if token doesn't exist
      next({ name: "login" });
    } else {
      // Continue navigation if token exists
      next();
    }
  } else {
    // Allow navigation for routes that don't require authentication
    next();
  }
});
export default router;
