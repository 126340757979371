<template>
  <div class="search">
    <input type="text" placeholder="search courses" />
    <button type="button">Search</button>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.search {
  position: relative;
  display: inline-block;
  margin: 50px 0;

  input {
    height: 7vh;
    border-radius: 30px;
    outline: none;
    border: 1px solid #2c3e50;
    padding: 0 10px;
    width: 80vh;
  }

  button {
    position: absolute;
    top: 50%;
    right: 1px;
    transform: translateY(-50%);
    background-color: #5e7d9c;
    color: #ffffff;
    outline: none;
    border: none;
    padding: 0 10px;
    border-radius: 30px;
    cursor: pointer;
    width: 20vh;
    height: 7vh;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .search {
    margin: 20px 0;

    input {
      height: 7vh;
      width: 60vh;
    }
  }
}

@media (max-width: 480px) {
  .search {
    input {
      height: 6vh;
      width: 40vh;
    }
    button {
      height: 6vh;
      width: 10vh;
    }
  }
}
</style>
