<template>
  <div class="footer">
    <div class="details">
      <div class="logo">
        <h4>Stadi</h4>
      </div>
      <div class="content">
        <div class="courses">
          <h5>Courses</h5>
          <p>First Aid</p>
          <p>Local Law</p>
          <p>Taxation</p>
          <p>Human Rights and Development</p>
          <p>Mental Wellness</p>
        </div>
      </div>
      <div class="content">
        <div class="company">
          <h5>Company</h5>
          <p>About Us</p>
          <p>Careers</p>
        </div>
      </div>
      <div class="content">
        <div class="community">
          <h5>Community</h5>
          <p>Blog</p>
          <p>Forum</p>
          <p>Recommend A Friend</p>
        </div>
      </div>
      <div class="content">
        <div class="help">
          <h5>Help</h5>
          <p>Support</p>
          <p>Privacy Policy</p>
          <p>Cookie Setting</p>
        </div>
      </div>
    </div>
    <p>&copy; 2024 Stadi</p>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.footer {
  margin: 50px 30px;

  .details {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between;
    text-align: start;

    .logo {
      width: 100%;
      margin-bottom: 20px; 
    }

    .content {
      width: 20%; 
      margin-bottom: 20px; 

      h5 {
        margin-bottom: 10px; 
      }

      p {
        font-size: 14px;
      }
    }
  }

  p {
    font-size: 14px;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
   

    .content {
      width: 100%;
    }
  }

  @media (max-width: 480px) {
   
    p {
      font-size: 12px; 
    }

    .content {
      h5 {
        font-size: 14px; 
      }

      p {
        font-size: 12px;
      }
    }
  }
}
</style>
